<template>
    <div style="max-width: 450px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="180px" class="login-form">
            <el-form-item prop="isInSpec" label="入规状态">
                <el-radio-group v-model="ruleForm.isInSpec">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { doInSpecFactory, unDoInSpecFactory } from '@/service/company';
    export default {
        props: ['item','ids'],
        data() {
            return {
                rules: {
                    isInSpec: [
                        { required: true, message: '请输入sendcloud模板id', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    isInSpec: null,
                },
            }
        },
        watch:{
            item:{
                handler() {
                    this.ruleForm = this.item;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.item;
            console.log("this.ruleForm",this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                if (this.ruleForm.spec_type) {
                    doInSpecFactory({ids: [this.item.id]}).then(rst => {
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    unDoInSpecFactory({ids: [this.item.id]}).then(rst => {
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped>
    .list-title>div:first-child {
        width:180px;
        padding:0 10px 20px 0;text-align:right;
    }
</style>