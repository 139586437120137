<template>
    <div style="height:100%">
        <div class="page-header">企业入规状态</div>
        <div class="flex" style="margin: 20px">
            <div class="flex-1"></div>
           <el-button @click="batchSet()" :disabled="!allSelectedId.length>0" size="small" type="primary">批量修改状态</el-button> 
        </div>
        <div style="height: calc(100% - 132px);">
            <div class="zt-block" style="height: calc(100% - 20px);">
                <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                    <el-table :data="list" style="width: 100%" :row-key="getRowKeys" 
                        ref="multipleTable"
                        @selection-change="handleSelectChange">
                        <el-table-column prop="id"
                            type="selection"
                            width="50">
                        </el-table-column>
                        <el-table-column prop="id" label="ID" width="120">
                        </el-table-column>
                        <el-table-column prop="name" label="客户名称">
                        </el-table-column>
                        <el-table-column prop="name" label="是否入规状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.isInSpec">是</span>
                                <span v-else>否</span>
                            </template>    
                        </el-table-column>
                        <el-table-column prop="params" label="操作">
                            <template slot-scope="scope">
                                <el-button @click="switchState(scope.row)" type="text">状态修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="padding:12px">
                    <el-pagination background layout="prev, pager, next" :current-page.sync="opt.page" :page-size="opt.pagesize" :total="total"></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditRuGui :item="ruleForm" :ids="allSelectedId" :popTitle="popTitle" @hide="hide"></EditRuGui>
        </el-dialog>
    </div>
</template>
<script>
    import { getFactoryList } from '@/service/bureau'
    import EditRuGui from './EditRuGui.vue';
    export default {
        components: {
            EditRuGui
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                list:[],
                ruleForm:null,
                opt: {
                    cert_stat: "",
                    k: "",
                    n_employee: "",
                    page: 1,
                    pagesize: 15,
                    reg_stat: "",
                    spec_stat: "",
                    zone_id: ""
                },
                total: 15,
                allSelectedId: [],
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getFactoryList(this.opt).then(rst => {
                    this.list = rst.list;
                    this.total = rst.total;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            getRowKeys(row) { 
                return row.id;
            },
            //确定选中
            handleSelectChange(rows) {
                console.log(rows);
                //将选中赋值到回显和传参数组
                this.templateRadio = rows;
                this.allSelectedId = [];
                if (rows) {
                    rows.forEach(row => {
                        this.allSelectedId.push(row.id);
                    });
                }
                console.log(this.allSelectedId);
            },
            switchState(item,index) {
                this.popTitle = "单个入规状态";
                this.popVisible = true;
                this.ruleForm = item;
            },
            batchSet() {
                this.popTitle = "多个入规状态";
                this.popVisible = true;
            },
            hide() {
                this.popVisible=false;
                this.refreshItems();
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    
</style>